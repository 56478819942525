import classNames from "classnames";
import React, { HTMLAttributes, useEffect } from "react";

type Props = {
  open: boolean;
  onClose: () => void;
  className?: HTMLAttributes<HTMLDivElement>["className"];
};

const SidebarShelf: React.FunctionComponent<Props> = ({
  open,
  onClose,
  children,
  className,
}) => {
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };
    addEventListener("keydown", handleEscapeKey);
    return () => {
      removeEventListener("keydown", handleEscapeKey);
    };
  }, [onClose]);

  return (
    <>
      <div
        className={classNames(
          "fixed top-0 left-0 overflow-y-scroll z-20",
          open ? "translate-x-0" : "-translate-x-full",
          "ease-in-out duration-300",
          className,
        )}
      >
        {children}
      </div>
      {open && (
        <>
          <div
            className={classNames(
              "fixed top-0 left-0 w-full h-full bg-grayscale-500 opacity-40 z-10",
            )}
            onClick={() => onClose()}
          ></div>
        </>
      )}
    </>
  );
};

export default SidebarShelf;
