import React, { HTMLAttributes } from "react";
import classNames from "classnames";

import {
  ExclamationCircleIcon,
  CheckIcon,
  ClockIcon,
} from "@heroicons/react/outline";

export type FeedbackMessagesProps = {
  status: "success" | "failed" | "pending"; // | "pending" | "refund" | "unpaid";
  messages: string[] | null | undefined | false | 0;
  showIcon?: boolean;
};

type StatusGroup = {
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  fgColor: HTMLAttributes<HTMLUListElement>["className"];
  bgColor: HTMLAttributes<HTMLUListElement>["className"];
  // fgStyle: TwStyle;
  // bgStyle: TwStyle;
};
type StatusDict = {
  [key in FeedbackMessagesProps["status"]]: StatusGroup;
};

const statuses: StatusDict = {
  failed: {
    Icon: ExclamationCircleIcon,
    fgColor: "text-error-medium",
    bgColor: "bg-error-very-light",
  },
  success: {
    Icon: CheckIcon,
    fgColor: "text-success-medium",
    bgColor: "bg-success-very-light",
  },
  pending: {
    Icon: ClockIcon,
    fgColor: "text-primary-blue-400",
    bgColor: "bg-primary-blue-100",
  },
};

const FeedbackMessages: React.FunctionComponent<FeedbackMessagesProps> = ({
  status,
  messages = [],
  showIcon = true,
}) => {
  const { Icon } = statuses[status];
  return messages && messages.length ? (
    <ul
      className={classNames(
        "font-medium text-medium rounded-lg",
        statuses[status].bgColor,
        statuses[status].fgColor,
      )}
    >
      {messages.map((m, i) => (
        <li key={i} className={"flex items-center"}>
          {showIcon && (
            <span className={"p-[9.5px]"}>
              <Icon className={"w-5 h-5 box-border"} />
            </span>
          )}
          <span className={"p-[9.5px] pl-0"}>{m}</span>
        </li>
      ))}
    </ul>
  ) : (
    <></>
  );
};

export default FeedbackMessages;
