import classNames from "classnames";
import { useRouter } from "next/router";
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from "react";
import NavierLogo from "../../public/assets/svg/reactor-xyz-logo-rbg.svg";
import NavierLogoBig from "../../public/assets/svg/NavierLogoBig.svg";
import Button from "../Button/Button";
import { useWallet } from "../WalletProvider";
import ConnectedWalletInfo from "./ConnectedWalletInfo";
import RequestPopup from "./RequestPopup";
import TooltipComponent from "../FormElements/TooltipComponent";
import Bitcoin from "../../public/assets/svg/btc.svg";

type Props = {
  onLogoClick?: () => void;
  onConnectWalletClick?: () => void;
  showZendeskWindow: () => void;
} & React.ComponentProps<typeof ConnectedWalletInfo> &
  DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>;

const Header: React.FunctionComponent<Props> = (props) => {
  const {
    onLogoClick,
    isMobile,
    onConnectWalletClick,
    className,
    showZendeskWindow,
  } = props;
  const { walletConnected, logOut, jwtToken, walletName } = useWallet();
  const router = useRouter();

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [tooltipText, setTooltipText] = useState("Click to copy");

  const headerLinks = (
    <div
      className={classNames(
        "justify-items-end flex flex-row",
        !isMobile && "gap-x-6 mr-[20px]",
      )}
    >
      {!isMobile && (
        <>
          <Button
            variant="link"
            onClick={() => {
              router.push("https://about.reactor.xyz");
            }}
          >
            About
          </Button>
        </>
      )}
      {!isMobile && (
        <RequestPopup showZendeskWindow={showZendeskWindow}></RequestPopup>
      )}
      <div className={classNames("flex flex-row")}>
        {walletConnected && !!jwtToken ? (
          <div
            onClick={() => setTooltipText("Copied!")}
            onMouseLeave={() => setTooltipText("Click to copy")}
          >
            <TooltipComponent
              tooltippable={
                <div className="target">
                  <ConnectedWalletInfo {...props} />
                </div>
              }
              tooltip={tooltipText}
            />
          </div>
        ) : (
          <Button
            className={"w-[240px]"}
            onClick={() => onConnectWalletClick?.()}
          >
            Login{" "}
            <Bitcoin className="transition duration-300 hover:-translate-y-1 inline mb-[2px]" />{" "}
          </Button>
        )}
        {walletConnected && jwtToken !== "" && (
          <Button
            className="ml-5"
            onClick={() => {
              logOut();
            }}
          >
            Log Out
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <header
      className={classNames(
        "bg-white flex justify-between items-center box-border max-w-[1500px] mx-auto",
        isMobile ? "pl-4 pr-4 min-h-[80px] py-2" : "px-8 h-24",
        className,
      )}
    >
      <div
        className={classNames(
          "align-left transform duration-300 hover:scale-90",
          isMobile ? "" : "ml-[20px]",
        )}
      >
        {windowWidth <= 460 ? (
          <NavierLogoBig
            className={classNames(
              "w-full h-full object-contain",
              "visited: cursor-pointer",
              isMobile && onLogoClick,
            )}
            onClick={() => onLogoClick?.()}
          />
        ) : (
          <NavierLogo
            className={classNames(
              "visited: cursor-pointer",
              isMobile && onLogoClick,
              isMobile && "w-full h-full object-contain",
            )}
            onClick={() => onLogoClick?.()}
          />
        )}
      </div>
      {headerLinks}
    </header>
  );
};

export default Header;
