import { FunctionComponent } from "react";

export interface InputTitleAndHelperProps {
  title?: string;
  helperText?: string;
}

const InputTitleAndHelper: FunctionComponent<InputTitleAndHelperProps> = ({
  children,
  title,
  helperText,
}) => {
  return (
    <>
      {title && <p className={"mb-2 font-medium"}>{title}</p>}
      {children}
      {helperText && (
        <p className={"mt-1 text-grayscale-600 text-xsmall font-regular"}>
          {helperText}
        </p>
      )}
    </>
  );
};

export default InputTitleAndHelper;
