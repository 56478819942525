import { useWallet } from "../WalletProvider";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { useBreakpoints } from "../util/mobileBreakpointHooks";
import React, { createContext, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import Sidebar from "../Sidebar/Sidebar";
import Hamburger from "../../public/assets/svg/hamburger.svg";
import ReactorLogo from "../../public/assets/svg/reactor-xyz-logo-rbg.svg";
import { useRouter } from "next/router";
import WalletConnectionModal from "../WalletConnection/WalletConnectionModal";
import ZendeskRequestForm from "../FormElements/ZendeskRequestForm";
import { FeedbackMessagesProvider } from "../FormElements/FeedbackMessagesManager";
import AlbyModal from "../FormElements/AlbyModal";
import { WaitlistProvider } from "../FormElements/WaitlistSignup";
import EmailModal from "../FormElements/EmailModal";
import Spinner from "../UIElements/Spinner";

export const LayoutContext = createContext<{
  sidebarShowing: boolean;
  openWalletConnectionModal: () => void;
  openFiatModal: () => void;
  openAlbyModal: () => void;
  openEmailModal: () => void;
  resetLayout: () => void;
  setBlur: () => void;
}>({
  sidebarShowing: false,
  openWalletConnectionModal: () => {},
  openFiatModal: () => {},
  openAlbyModal: () => {},
  openEmailModal: () => {},
  resetLayout: () => {},
  setBlur: () => {},
});

interface Props {
  withSidebar?: boolean;
}

const BaseLayout: React.FunctionComponent<Props> = ({
  children,
  withSidebar = false,
}) => {
  const { walletName, accountAddress, initializing } = useWallet();

  const router = useRouter();

  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const { isTabletOrSmaller, is1024OrSmaller } = useBreakpoints();

  const [sidebarShowing, setSidebarShowing] = useState<boolean>(false);
  const [blurred, setBlurred] = useState<boolean>(false);

  const [walletConnectionModalOpen, setWalletConnectionModalOpen] =
    useState<boolean>(false);
  const [fiatModalOpen, setFiatModalOpen] = useState<boolean>(false);
  const [albyModalOpen, setAlbyModalOpen] = useState<boolean>(false);
  const [requestForm, setRequestForm] = useState<boolean>(false);
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  // this defeats all SSR below this level in the app
  useEffect(() => {
    if (!initializing) setPageLoaded(true);
  }, [initializing]);

  // close sidebar, unblur page, etc etc, after a path change (eg if user just clicked a sidebar link)
  useEffect(() => {
    resetLayout();
  }, [router.asPath]);

  const setBlur = () => {
    setBlurred(true);
  };

  const resetLayout = () => {
    setBlurred(false);
    setSidebarShowing(false);

    setWalletConnectionModalOpen(false);
    setEmailModalOpen(false);
    setAlbyModalOpen(false);
    setRequestForm(false);
    setFiatModalOpen(false);
  };
  const openWalletConnectionModal = () => {
    setWalletConnectionModalOpen(true);
  };
  const openSidebar = () => {
    setSidebarShowing(true);
    setBlurred(true);
  };

  const showZendeskWindow = () => {
    setRequestForm(true);
  };
  const openFiatModal = () => {
    setFiatModalOpen(true);
  };
  const openAlbyModal = () => {
    setAlbyModalOpen(true);
  };
  const openEmailModal = () => {
    setEmailModalOpen(true);
  };

  useEffect(() => {
    setBlurred(
      sidebarShowing ||
        albyModalOpen ||
        emailModalOpen ||
        walletConnectionModalOpen ||
        requestForm ||
        fiatModalOpen,
    );
  }, [
    sidebarShowing,
    albyModalOpen,
    emailModalOpen,
    walletConnectionModalOpen,
    requestForm,
    fiatModalOpen,
  ]);

  return (
    <FeedbackMessagesProvider>
      <WaitlistProvider>
        <LayoutContext.Provider
          value={{
            sidebarShowing,
            openWalletConnectionModal,
            openFiatModal,
            openAlbyModal,
            openEmailModal,
            resetLayout,
            setBlur,
          }}
        >
          {true && (
            <>
              <div
                className={classNames(
                  "flex flex-col justify-between min-h-screen relative z-10",
                )}
              >
                {pageLoaded ? (
                  <div>
                    <div className="bg-white">
                      <Header
                        walletName={walletName}
                        accountAddress={accountAddress}
                        isMobile={isTabletOrSmaller}
                        onLogoClick={() => {
                          router.push("/");
                        }}
                        onConnectWalletClick={() => openWalletConnectionModal()}
                        showZendeskWindow={showZendeskWindow}
                        className={classNames(blurred && "blur-sm")}
                      />
                    </div>
                    {walletConnectionModalOpen && (
                      <WalletConnectionModal
                        modalOpen={walletConnectionModalOpen}
                        onCloseModal={resetLayout}
                      />
                    )}
                    {emailModalOpen && (
                      <EmailModal
                        modalOpen={emailModalOpen}
                        onCloseModal={resetLayout}
                      />
                    )}
                    {albyModalOpen && (
                      <AlbyModal
                        modalOpen={albyModalOpen}
                        onCloseModal={resetLayout}
                      />
                    )}
                    {requestForm && (
                      <ZendeskRequestForm
                        modalOpen={requestForm}
                        onCloseModal={resetLayout}
                      />
                    )}
                    <div className={"flex justify-center"}>
                      <div
                        className={classNames(
                          "flex",
                          "w-full max-w-[1450px] px-6",
                          !isTabletOrSmaller && blurred && "blur-sm",
                        )}
                      >
                        {withSidebar && <Sidebar />}

                        <div
                          className={classNames(
                            withSidebar &&
                              is1024OrSmaller &&
                              !isTabletOrSmaller &&
                              "mx-9",
                            withSidebar &&
                              !is1024OrSmaller &&
                              !isTabletOrSmaller &&
                              "mx-12",
                            withSidebar &&
                              (isTabletOrSmaller ? "pb-6 mt-8" : "pb-6 mt-16"),
                            "w-full",
                            blurred && "blur-sm",
                          )}
                        >
                          {withSidebar && isTabletOrSmaller && (
                            <div className={""}>
                              <Hamburger
                                className={"cursor-pointer"}
                                onClick={() => openSidebar()}
                              />
                            </div>
                          )}
                          {children}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center mt-20">
                    <div className="flex items-center mb-4">
                      <ReactorLogo />
                      <h4 className="ml-2">is loading</h4>
                    </div>
                    <div>
                      <Spinner size="w-12 h-12" bold />
                    </div>
                  </div>
                )}
                <Footer
                  blurred={blurred}
                  showZendeskWindow={showZendeskWindow}
                />
              </div>
            </>
          )}
        </LayoutContext.Provider>
      </WaitlistProvider>
    </FeedbackMessagesProvider>
  );
};

export default BaseLayout;

export const useLayout = () => {
  const layoutContext = useContext(LayoutContext);
  if (layoutContext === undefined) {
    throw new Error("useWallet must be used in a child of WalletProvider");
  }
  return layoutContext;
};
