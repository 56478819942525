import WalletConnectIcon from "../../public/assets/svg/walletconnect.svg";
import FortmaticIcon from "../../public/assets/svg/fortmatic.svg";
import CoinbaseWalletIcon from "../../public/assets/svg/coinbase-wallet.svg";
import Lightning from "../../public/assets/svg/Lightning.svg";
import Email from "../../public/assets/svg/emailIcon.svg";

export type Wallet<K = string> = {
  displayName: K;
  Icon: React.VFC<React.SVGProps<SVGSVGElement>>;
  connect: () => void;
};

export const emailIcon: React.VFC<React.SVGProps<SVGSVGElement>> = () => (
  <a className="flex overflow-hidden justify-center items-center rounded-full bg-navier-blue transition duration-300 hover:-translate-y-1 p-2">
    <Email className="w-full h-full" />
  </a>
);

export const lightningIcon: React.VFC<React.SVGProps<SVGSVGElement>> = () => (
  <a className="flex overflow-hidden justify-center items-center rounded-full transition duration-300 hover:-translate-y-1">
    <Lightning />
  </a>
);

/**
 * It is agreed for now that this is a strange mixture of data and implementation,
 * hence the name "configurations"
 *
 * This, or parts of it may be moved or refactored into other places as we implement
 * other wallet connections and complete the integration of components and logic
 */

export const otherWallets = [
  {
    displayName: "Connect with email",
    Icon: emailIcon,
    connect: () => console.log("Connect with email"),
  },
  {
    displayName: "Connect with Lightning",
    Icon: lightningIcon,
    connect: () => {
      console.log("Connect with Lightning");
    },
  },
  {
    displayName: "WalletConnect",
    Icon: WalletConnectIcon,
    connect: () => console.log("Connect to WalletConnect."),
  },
  {
    displayName: "Fortmatic",
    Icon: FortmaticIcon,
    connect: () => console.log("Connect to Fortmatic."),
  },
  {
    displayName: "Coinbase Wallet",
    Icon: CoinbaseWalletIcon,
    connect: () => console.log("Connect to Coinbase Wallet."),
  },
];

export const walletConfigurations = (<T,>(p: readonly Wallet<T>[]) => p)([
  ...otherWallets,
]);

// prettier-ignore
export type WalletName = (typeof walletConfigurations)[number]["displayName"];
// prettier-ignore
export type WalletConfiguration = (typeof walletConfigurations)[number];
