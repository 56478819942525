import classNames from "classnames";

const Spinner: React.FunctionComponent<{ bold?: boolean; size?: string }> = ({
  bold = false,
  size,
}) => (
  <div
    className={classNames(
      "spinner-border animate-spin inline-block rounded-full",
      bold ? "border-4" : "border-2",
      size ? size : "w-4 h-4",
    )}
    role="status"
  >
    <span className="visually-hidden">Loading...</span>
  </div>
);

export default Spinner;
