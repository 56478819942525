import classNames from "classnames";
import React, { useState } from "react";
import Button from "../Button/Button";
import { useBreakpoints } from "../util/mobileBreakpointHooks";

interface RequestPopupProps {
  showZendeskWindow: () => void;
}

const RequestPopup: React.FunctionComponent<RequestPopupProps> = ({
  showZendeskWindow,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const togglePopup = () => {
    showZendeskWindow();
    setShowPopup(!showPopup);
  };

  return (
    <>
      <Button variant="link" onClick={togglePopup}>
        Contact Support
      </Button>
    </>
  );
};

export default RequestPopup;
