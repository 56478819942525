import React from "react";
import classNames from "classnames";
import { WalletName } from "./walletConfigurations";
import { HTMLButtonProps } from "../../services/reactor/types";

type PropsType = {
  Icon: React.VFC<React.SVGProps<SVGSVGElement>>;
  walletName: WalletName;
} & HTMLButtonProps;

const WalletConnectionButton: React.FunctionComponent<PropsType> = (props) => {
  const { Icon, walletName, onClick, className } = props;
  return (
    <button
      className={classNames(
        "w-full sm:w-52 h-16 sm:h-36 rounded-lg border border-grayscale-250 bg-grayscale-25 hover:bg-grayscale-50 box-border flex sm:justify-center items-center p-2 hover:active:bg-grayscale-25",
        className,
      )}
      onClick={(e) => onClick && onClick(e)}
    >
      <div className={"w-full flex sm:flex-col items-center"}>
        <div
          className={
            "w-[50px] h-[50px] flex-grow-0 flex items-center justify-center"
          }
        >
          <Icon />
        </div>
        <p
          className={
            "text-secondary-black-500 mt-0 sm:mt-2 ml-2 sm:ml-0 text-sm font-thicccboi flex-grow sm:flex-grow-0 text-center"
          }
        >
          {walletName}
        </p>
      </div>
    </button>
  );
};

export default WalletConnectionButton;
