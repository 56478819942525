import classNames from "classnames";
import React, { useCallback, useEffect, useState, useRef } from "react";
import Button from "../Button/Button";
import Image from "next/image";
import TextInput from "./TextInput";
import { useWallet } from "../WalletProvider";
import {
  useFeedbackMessagesFunctions,
  FeedbackMessagesManager,
} from "./FeedbackMessagesManager";
import QRCode from "qrcode";
import TooltipComponent from "./TooltipComponent";
import { getJwtToken, getLnurlKeys } from "../../services/reactor/api";
import { ModalProps } from "../../services/reactor/types";
import Dialog from "../Dialog/Dialog";

const AlbyModal: React.FunctionComponent<ModalProps> = (props) => {
  const { modalOpen, onCloseModal } = props;

  const { setJWT, walletConnected } = useWallet();
  const { addMessage, removeMessage, messages } =
    useFeedbackMessagesFunctions();

  const [albyBech, setAlbyBech] = useState("");
  const [beginLoop, setBeginLoop] = useState(false);

  const [lnurlCopied, setLnurlCopied] = useState(false);
  const [lnurlLoading, setlnurlLoading] = useState(false);

  const [secret, setSecret] = useState<string>("");
  const [method, setMethod] = useState<string>("");
  const [k1, setK1] = useState<string>("");

  const getLnurl = async () => {
    setlnurlLoading(true);
    await connectLnurlWallet();
    return;
  };

  const connectLnurlWallet = async () => {
    getLnurlKeys()
      .then((out) => {
        if (out.lnurl) {
          setMethod("k1");
          setK1(out.k1);
          setSecret(out.secret);
          setAlbyBech(out.lnurl);
          setlnurlLoading(false);
          return "success";
        }
      })
      .catch((e) => {
        console.log("failed getting lnurl keys ", e);
      });
    return "failed";
  };

  const jwtLogin = useCallback(async () => {
    if (k1 && secret && method) {
      getJwtToken(method, k1, secret)
        .then((out) => {
          if (out.status === "pending") {
            console.log("Log in pending");
          }
          if (out.status === "success") {
            // login the user
            console.log("setting JWT");
            setJWT(out.jwt, "Connect with Lightning", out.expiry);
          }
        })
        .catch((a) => {
          console.log("Still searching for connected wallet ", a);
        });
    } else {
      console.log("returning because no k1");
    }
  }, [k1, secret, method, setJWT]);

  useEffect(() => {
    if (walletConnected) {
      setBeginLoop(false);
      removeMessage(
        "Searching for connected wallet. This window will automatically close once connected.",
      );
      setTimeout(onCloseModal, 200);
    }
  }, [walletConnected, removeMessage, onCloseModal]);

  // get an albybech
  useEffect(() => {
    if (albyBech || lnurlLoading) return;
    getLnurl();
  });

  // when an alby bech is fetched, begin the log in loop
  useEffect(() => {
    if (albyBech) setBeginLoop(true);
  }, [albyBech]);

  // loop login once albyBech and beginLoop are true
  useEffect(() => {
    if (!beginLoop || !albyBech) return;
    const intervalId = setInterval(() => {
      jwtLogin();
    }, 3000);

    // Clean up the interval on unmount
    return () => clearInterval(intervalId);
  }, [beginLoop, jwtLogin, albyBech]);

  const [qrSrc, setQrSrc] = useState<string | null>(null);

  const qrFromHash = (hash: string) => {
    return new Promise<string>((resolve, reject) => {
      try {
        const qrCodeData = QRCode.create(hash, { errorCorrectionLevel: "H" });
        const scale = Math.floor(300 / qrCodeData.modules.size);

        const qrSrc = QRCode.toDataURL(hash, {
          errorCorrectionLevel: "H",
          type: "image/png",
          scale: scale,
          margin: 0,
        });
        resolve(qrSrc);
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (albyBech) {
      qrFromHash(albyBech)
        .then(setQrSrc)
        .catch((error) => {
          console.error("Failed to create QR code:", error);
        });
    }
  }, [albyBech]);

  const [tooltipText, setTooltipText] = useState("Click to copy");

  const hashCopy = (str: string) => {
    const inner = (
      <TextInput
        title=""
        className="w-full"
        value={str}
        readOnly={true}
        onClick={() => {
          try {
            navigator.clipboard.writeText(str);
            setTooltipText("Copied!");
            setLnurlCopied(true);
            addMessage({
              msg: "Searching for connected wallet. This window will automatically close once connected.",
              status: "pending",
              autoClear: 0,
            });
          } catch (e) {
            addMessage({
              msg: "Error copying to keyboard",
              status: "failed",
            });
          }
        }}
      />
    );

    return (
      <div
        onClick={() => setTooltipText("Copied!")}
        onMouseLeave={() => setTooltipText("Click to copy")}
      >
        <TooltipComponent
          tooltippable={inner}
          tooltip={tooltipText}
        ></TooltipComponent>
      </div>
    );
  };

  console.log("beginLoop :>> ", beginLoop);

  return (
    <Dialog
      dialogOpen={modalOpen}
      onClose={() => {
        onCloseModal();
        setBeginLoop(false);
        setAlbyBech("");
        setSecret("");
        setMethod("");
        setK1("'");
        removeMessage(
          "Searching for connected wallet. This window will automatically close once connected.",
        );
      }}
      className={classNames(
        "sm:w-[450px] text-center",
        "box-border pt-6 pb-9 px-6",
        "px-8 py-6 bg-white rounded-lg shadow-lg text-secondary-black-500 border border-grayscale-200 rounded-2xl",
      )}
    >
      <div className={classNames("flex justify-center")}>
        <div>
          {albyBech ? (
            <>
              {albyBech && qrSrc && (
                <div className="flex justify-center items-center mb-10 w-full">
                  <Image
                    src={qrSrc}
                    loading="lazy"
                    alt="QR Code"
                    width="350"
                    height="350"
                  />
                </div>
              )}
              <div className="mb-4 w-full">
                Click to copy the LNURL below. In your lightning wallet, click
                send and paste the address. Alternatively, scan the QR code
                above with your wallet to log in.
              </div>
              {albyBech && hashCopy(albyBech)}
            </>
          ) : (
            "QR code loading"
          )}
          <div className="mt-3">
            {!lnurlCopied && (
              <Button variant="secondary" onClick={onCloseModal}>
                {albyBech ? "Close" : "Cancel"}
              </Button>
            )}
            {messages && <FeedbackMessagesManager />}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default AlbyModal;
