import React from "react";
import classNames from "classnames";
import { HTMLInputProps } from "../../services/reactor/types";
import InputTitleAndHelper, {
  InputTitleAndHelperProps,
} from "./InputTitleAndHelper";

/**
 * Input field matching the Figma Design system.  You can supply
 * the same props you would on an ordinary HTML input, but any className value
 * will be applied after the existing default styles, giving it higher CSS
 * specificity, but meaning you'd have explicitly overwrite any existing
 * classnames.
 *
 * For convenience, supports title and helper text, but those could be rendered manually
 * as well.
 *
 * Ref is forwarded to the <input /> element to support integration with form libraries.
 */
const TextInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  HTMLInputProps & InputTitleAndHelperProps & { required?: boolean }
> = (props, ref) => {
  const { className, title, helperText, required, ...remainingProps } = props;

  return (
    <InputTitleAndHelper title={title} helperText={helperText}>
      <input
        className={classNames(
          "w-full block",
          "p-4 rounded-xl border border-grayscale-300 text-large font-medium placeholder-grayscale-300 placeholder:font-normal",
          "focus:border-primary-blue-500 focus:outline-none",
          className,
        )}
        {...remainingProps}
        ref={ref}
        required={required}
      />
    </InputTitleAndHelper>
  );
};
export default React.forwardRef(TextInput);
