import { useMediaQuery } from "react-responsive";
const defaultTheme = require("tailwindcss/defaultTheme");

export const MOBILE_BREAKPOINT_VALUE = defaultTheme.screens.xs;
export const TABLET_BREAKPOINT_VALUE = defaultTheme.screens.md;
export const DESKTOP_BREAKPOINT_VALUE = defaultTheme.screens.lg;

export const useIsMobile = () =>
  useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT_VALUE})`,
  });

export const useBreakpoints = () => ({
  isMobileOrSmaller: useMediaQuery({
    query: `(max-width: ${MOBILE_BREAKPOINT_VALUE})`,
  }),
  isTabletOrSmaller: useMediaQuery({
    query: `(max-width: ${TABLET_BREAKPOINT_VALUE})`,
  }),
  is1024OrSmaller: useMediaQuery({
    query: `(max-width: ${DESKTOP_BREAKPOINT_VALUE})`,
  }),
  is1152OrSmaller: useMediaQuery({
    query: `(max-width: ${DESKTOP_BREAKPOINT_VALUE})`,
  }),
});
