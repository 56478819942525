import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Button from "../Button/Button";
import { useWallet } from "../WalletProvider";
import {
  useFeedbackMessagesFunctions,
  FeedbackMessagesManager,
} from "./FeedbackMessagesManager";
import TextInput from "./TextInput";
import {
  LogIn,
  registerUser,
  requestEmailVerification,
  resetPasswordRequest,
} from "../../services/reactor/api";
import Spinner from "../UIElements/Spinner";
import { useRouter } from "next/router";
import Dialog from "../Dialog/Dialog";
import { ModalProps } from "../../services/reactor/types";
import NavierLogoFull from "../../public/assets/svg/reactor-xyz-logo-rbg.svg";

const EmailModal: React.FunctionComponent<ModalProps> = (props) => {
  const { modalOpen, onCloseModal } = props;

  const { setJWT, walletConnected } = useWallet();
  const { addMessage, removeMessage, messages } =
    useFeedbackMessagesFunctions();
  const router = useRouter();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showSignup, setShowSignup] = useState(false);

  const [load, setLoad] = useState(false);

  const [waitingForVerification, setWaitingForVerification] = useState(false);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [hasSentPasswordReset, setHasSentPasswordReset] = useState(false);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setConfirmPassword(e.target.value);
  };

  function validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  function validatePassword(password: string) {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;
    return regex.test(password);
  }

  const logInValidation = (email: string, password: string) => {
    if (!email || !password) {
      addMessage({ msg: "Email and password are required", status: "failed" });
      return false;
    }

    if (!validateEmail(email)) {
      addMessage({ msg: "Not an email", status: "failed" });
      return false;
    }
    return true;
  };

  const handleLogin = () => {
    if (!logInValidation(email, password)) return;

    setLoad(true);

    LogIn({ email, password })
      .then((out) => {
        if (out.status === "success") {
          setJWT(out.jwt, "Connect with email", out.expiry);
          onCloseModal();
          if (
            // redirect user if they are at password reset or email verification
            router.pathname !== "/" &&
            router.pathname !== "/dashboard" &&
            router.pathname !== "/config" &&
            router.pathname !== "/admin"
          ) {
            setTimeout(() => {
              router.push("/");
            }, 300);
          }
        }
      })
      .catch((error) => {
        addMessage({
          msg: error.message ? error.message : "Log in failed",
          status: "failed",
        });

        setLoad(false);
      });
  };

  const handleSignup = () => {
    if (!email || !password || !confirmPassword) {
      addMessage({ msg: "All fields are required", status: "failed" });
      return;
    }

    if (!validateEmail(email)) {
      addMessage({ msg: "Not an email", status: "failed" });
      return;
    }

    if (password !== confirmPassword) {
      addMessage({ msg: "Passwords do not match", status: "failed" });
      return;
    }

    if (!validatePassword(password)) {
      addMessage({
        msg: "Password must be at least 8 characters long and contain uppercase, lowercase, number, and special characters.",
        status: "failed",
      });
      return;
    }

    setLoad(true);

    registerUser({ email, password })
      .then((e) => {
        addMessage({
          msg: "Successfully registered, please check your email",
          status: "success",
        });

        setPassword("");
        setConfirmPassword("");

        setLoad(false);
        setShowSignup(false);
        setWaitingForVerification(true);
      })
      .catch((e) => {
        addMessage({
          msg: e.message ? e.message : "Registration failed",
          status: "failed",
        });
        setLoad(false);
      });
  };

  const handleRequestVerificationAgain = () => {
    setLoad(true);
    requestEmailVerification(email)
      .then((e) => {
        addMessage({ msg: e, status: "success" });
      })
      .catch((e) => {
        addMessage({
          msg: e.message ? e.message : "Verification request failed",
          status: "failed",
        });
      });

    setLoad(false);
  };

  const handlePasswordReset = () => {
    if (!validateEmail(email)) {
      addMessage({ msg: "Invalid email", status: "failed" });
      return;
    }
    setLoad(true);
    resetPasswordRequest(email)
      .then((e) => {
        setHasSentPasswordReset(true);
      })
      .catch((e) => {
        addMessage({
          msg: e.message ? e.message : "Password reset fequest failed",
          status: "failed",
        });
      });

    setLoad(false);
  };

  useEffect(() => {
    if (walletConnected) {
      setEmail("");
      setPassword("");
      setConfirmPassword("");

      setTimeout(onCloseModal, 200);
    }
  }, [walletConnected, removeMessage, onCloseModal]);

  const forgotPasswordModal = (
    <>
      {hasSentPasswordReset ? (
        <div className="mb-2">
          A password reset link has been sent to {email}, please continue in
          that page
        </div>
      ) : (
        <div>
          <h6>Enter your account email to reset your password</h6>
          <div className="flex justify-center mt-3">
            <TextInput
              type="email"
              placeholder="Email"
              value={email}
              onChange={handleEmailChange}
              disabled={load}
            />
          </div>
          <div className="py-2">
            <a
              onClick={() => {
                if (load) return;
                setForgotPassword(false);
              }}
              className={classNames(
                "inline-block text-primary-blue-500",
                !load && "hover: cursor-pointer",
              )}
            >
              Log In
            </a>
          </div>
        </div>
      )}
    </>
  );

  const waitingForVerificationModal = (
    <div className="text-xlarge mb-3">
      Email verification has been sent.<br></br>Once confirmed, you can{" "}
      <a
        onClick={() => {
          if (!load) {
            setShowSignup(false);
            setWaitingForVerification(false);
          }
        }}
        className={classNames(
          "inline-block text-primary-blue-500",
          !load && "hover: cursor-pointer",
        )}
      >
        Log In
      </a>
    </div>
  );

  const emailPasswordInputs = (
    <>
      <div className="flex justify-center mt-3 mb-3">
        <TextInput
          type="email"
          placeholder="Email"
          name="username"
          id="username"
          autoComplete="email"
          required
          value={email}
          onChange={handleEmailChange}
          disabled={load}
        />
      </div>
      <div className="flex justify-center mt-3 mb-1">
        <TextInput
          type="password"
          placeholder="Password"
          id="password"
          required
          value={password}
          onChange={handlePasswordChange}
          disabled={load}
        />
      </div>
    </>
  );

  const logInModal = (
    <>
      <h5>Log In</h5>
      {emailPasswordInputs}
    </>
  );

  const registerModal = (
    <div>
      <>
        <h5>Sign Up</h5>
        {emailPasswordInputs}
        <div className="flex justify-center mt-3">
          <TextInput
            type="password"
            id="password"
            placeholder="Confirm Password"
            required
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            disabled={load}
          />
        </div>
      </>
    </div>
  );

  const logInOrRegisterModal = (
    <>
      {showSignup ? registerModal : logInModal}
      <div className="flex justify-center gap-x-1">
        {!showSignup && (
          <div className="my-2">
            <a
              onClick={() => {
                if (load) return;
                setPassword("");
                setConfirmPassword("");
                setForgotPassword(true);
              }}
              className={classNames(
                "inline-block text-primary-blue-500 mr-1",
                !load && "hover: cursor-pointer",
              )}
            >
              Forgot Password
            </a>
            or
          </div>
        )}
        <a
          onClick={() => {
            if (!load) setShowSignup(!showSignup);
          }}
          className={classNames(
            "inline-block text-primary-blue-500 my-2",
            !load && "hover: cursor-pointer",
          )}
        >
          {showSignup ? "Log In" : "Sign Up"}
        </a>
      </div>
    </>
  );

  const currentButtonText = waitingForVerification
    ? "Resend Request"
    : forgotPassword
      ? hasSentPasswordReset
        ? "Log In"
        : "Send Reset Request"
      : showSignup
        ? "Sign Up"
        : "Log In";

  return (
    <Dialog
      dialogOpen={modalOpen}
      onClose={() => {
        if (!load) {
          onCloseModal();
          setLoad(false);
          setEmail("");
          setPassword("");
          setConfirmPassword("");
        }
      }}
      className={classNames(
        "sm:w-[450px]",
        "text-center",
        "px-8 py-6 bg-white rounded-lg shadow-lg text-secondary-black-500 border border-grayscale-200 rounded-2xl",
      )}
    >
      <div className={classNames("flex justify-center")}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (hasSentPasswordReset) {
              setShowSignup(false);
              setForgotPassword(false);
            } else if (waitingForVerification) handleRequestVerificationAgain();
            else if (forgotPassword) handlePasswordReset();
            else if (showSignup) handleSignup();
            else handleLogin();
          }}
        >
          <div className="sm:w-[400px]">
            <div className="flex flex-col items-center mb-2">
              <NavierLogoFull />
            </div>

            <div className="mt-4">
              {forgotPassword
                ? forgotPasswordModal
                : waitingForVerification
                  ? waitingForVerificationModal
                  : logInOrRegisterModal}
            </div>
          </div>
          <div className="flex justify-center items-center gap-4">
            <div className="hidden">
              {/* Hidden submit button that will capture the "Enter" key */}
              <button type="submit" style={{ display: "none" }} />
            </div>
            <Button
              variant="secondary"
              onClick={(e) => {
                e.preventDefault();
                onCloseModal();
              }}
              disabled={load}
            >
              Close
            </Button>
            <Button
              className={"w-full"}
              variant="primary"
              type="submit"
              disabled={
                waitingForVerification
                  ? false
                  : load ||
                    !email ||
                    (!forgotPassword && !password) ||
                    (showSignup && !confirmPassword)
              }
            >
              {load ? <Spinner /> : currentButtonText}
            </Button>
          </div>
          {messages.length > 0 && (
            <div className="mt-3">
              <FeedbackMessagesManager />
            </div>
          )}
        </form>
      </div>
    </Dialog>
  );
};

export default EmailModal;
