import React from "react";
import classNames from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import { HTMLDivProps } from "../../services/reactor/types";

type PropsType = {
  dialogOpen: boolean;
  onClose: () => void;
  header?: React.ReactNode;
} & HTMLDivProps;

const HeadlessUIDialog: React.FunctionComponent<PropsType> = (props) => {
  const { dialogOpen, onClose, header, children, className } = props;
  return (
    <Transition appear show={dialogOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto bg-slate-500/20"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle bg-slate-400"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              className={classNames(
                "inline-block w-full p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-lg",
                className && className,
              )}
            >
              {header && (
                <Dialog.Title
                  as={typeof header === "string" ? "div" : "h3"}
                  className={classNames(
                    "font-medium leading-6 text-gray-900",
                    typeof header === "string" && "text-xl",
                  )}
                >
                  {header}
                </Dialog.Title>
              )}
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};
export default HeadlessUIDialog;
