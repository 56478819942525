import React from "react";
import classNames from "classnames";
import { HTMLLabelProps, HTMLInputProps } from "../../services/reactor/types";

type CheckboxPropsType = {
  /**
   * name must be unique—user of this checkbox is responsible to enforce
   */
  disabled?: boolean;
  name: string;
  checked?: boolean;
  inputProps?: Omit<HTMLInputProps, "name" | "type" | "checked" | "onChange">;
  labelProps?: Omit<HTMLLabelProps, "htmlFor">;
  label?: React.ReactNode;
  onChange?: HTMLInputProps["onChange"];
};

const Checkbox: React.ForwardRefRenderFunction<
  HTMLInputElement,
  CheckboxPropsType
> = (props, ref) => {
  const { disabled, name, inputProps, labelProps, label, onChange } = props;

  const controlled = React.useMemo(
    () => Object.keys(props).includes("checked"),
    [props],
  );

  const { inputClassName, remainingInputProps } = React.useMemo(() => {
    if (inputProps) {
      const { className: inputClassName, ...remainingInputProps } = inputProps;
      return {
        inputClassName,
        remainingInputProps,
      };
    } else {
      return {};
    }
  }, [, inputProps]);

  const { labelClassName, remainingLabelProps } = React.useMemo(() => {
    if (labelProps) {
      const { className: labelClassName, ...remainingLabelProps } = labelProps;
      return {
        labelClassName,
        remainingLabelProps,
      };
    } else {
      return {};
    }
  }, [labelProps]);

  return (
    <div className={"flex items-start"}>
      <div className="flex items-center h-6">
        <input
          className={classNames(
            'mr-2 rounded-full appearance-none w-6 h-6 box-border border border-grayscale-300 bg-white text-white text-center checked:border-0 checked:bg-blue-500 checked:before:content-[url("/assets/svg/check.svg")] cursor-pointer',
            inputClassName,
          )}
          {...(controlled ? { checked: props.checked } : {})}
          disabled={disabled}
          name={name}
          id={name}
          type={"checkbox"}
          onChange={onChange}
          {...remainingInputProps}
          ref={ref}
        />
      </div>
      {label && (
        <div className="flex items-center h-6">
          <label
            className={classNames(
              "cursor-pointer text-medium font-medium",
              labelClassName,
            )}
            htmlFor={name}
            {...remainingLabelProps}
          >
            {label}
          </label>
        </div>
      )}
    </div>
  );
};

export default React.forwardRef(Checkbox);
