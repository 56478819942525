import { Popover } from "@headlessui/react";
import classNames from "classnames";
import React, { useState } from "react";

const TooltipComponent: React.FC<{
  tooltippable: React.ReactNode;
  tooltip: React.ReactNode;
  children?: React.ReactNode;
}> = ({ tooltippable, tooltip, children }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <Popover className="relative">
      <Popover.Button
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => {
          setShowTooltip(false);
        }}
        className="w-full"
      >
        {tooltippable}
      </Popover.Button>
      {showTooltip && (
        <div className="relative flex items-center justify-center text-center">
          <div className="absolute top-full left-1/2 transform -translate-x-1/2 z-20 p-2 mt-2 rounded-2xl shadow-lg border border-grayscale-200 bg-white">
            <Popover.Panel static>{tooltip}</Popover.Panel>
          </div>
        </div>
      )}
    </Popover>
  );
};

export default TooltipComponent;
