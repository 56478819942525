import classNames from "classnames";
import Link from "next/link";
import React from "react";
import { HTMLDivProps } from "../../services/reactor/types";

type BaseProps = {
  isMobile?: boolean;
  Icon?: React.VFC<React.SVGProps<SVGSVGElement>>;
  disabled?: boolean;
  active?: boolean;
};

type PropsWithOnClick = {
  href?: never;
} & Pick<HTMLDivProps, "className" | "onClick">;

type PropsWithHref = {
  href: string;
  onClick?: never;
} & Pick<HTMLDivProps, "className">;

type Props = (PropsWithOnClick | PropsWithHref) & BaseProps;

const SidebarLink: React.FunctionComponent<Props> = (props) => {
  const {
    active = false,
    disabled = false,
    children,
    className,
    Icon,
    isMobile = false,
  } = props;

  const guts = (
    <div
      className={classNames(
        "h-12 rounded-xl w-full px-4 box-border",
        !disabled && "cursor-pointer",
        "flex items-center",
        "font-medium",
        isMobile ? "text-small" : "text-medium",
        active
          ? "bg-grayscale-600 text-white"
          : "bg-transparent border-white text-grayscale-600",
        active
          ? `${disabled ? "" : "hover:bg-grayscale-500"}`
          : `border-0 border-grayscale-500 ${
              disabled
                ? ""
                : "hover:border-grayscale-600 hover:text-grayscale-700"
            }`,
        className,
      )}
      onClick={(e) => !disabled && props.onClick?.(e)}
    >
      {Icon && (
        <div className={"mr-3"}>
          {/* <Icon width="20px" height="21px" /> */}
          <Icon />
        </div>
      )}
      {children}
    </div>
  );

  return disabled ? <>{guts}</> : <Link href={props.href ?? ""}>{guts}</Link>;
};

export default SidebarLink;
