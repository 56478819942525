import React from "react";
import Dialog from "../Dialog/Dialog";
import WalletConnectionButton from "./WalletConnectionButton";
import { walletConfigurations } from "./walletConfigurations";
import { useWallet } from "../WalletProvider";
import { useLayout } from "../BaseLayout/BaseLayout";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { ModalProps } from "../../services/reactor/types";

const LIGHTNING_ENABLED =
  process.env.NEXT_PUBLIC_LIGHTNING_LOGIN_ENABLED === undefined
    ? true
    : process.env.NEXT_PUBLIC_LIGHTNING_LOGIN_ENABLED;

const EMAIL_ENABLED =
  process.env.NEXT_PUBLIC_EMAIL_LOGIN_ENABLED === undefined
    ? true
    : process.env.NEXT_PUBLIC_EMAIL_LOGIN_ENABLED;

const activeWalletConfigurations = walletConfigurations.filter(
  (wallet) =>
    (LIGHTNING_ENABLED && wallet.displayName === "Connect with Lightning") ||
    (EMAIL_ENABLED && wallet.displayName === "Connect with email"),
);

const WalletConnectionModalHeader = () => (
  <>
    <h6 className="text-secondary-black-500">Log In or Sign Up</h6>
    <p className="mt-2 text-secondary-black-500">
      By connecting with Reactor, you agree to Reactor&apos;s{" "}
      {
        <>
          {" "}
          <a
            target={"_blank"}
            rel="noreferrer"
            href={"https://about.reactor.xyz/terms-of-use"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            Terms of Use
          </a>
        </>
      }
      .
    </p>
  </>
);

const WalletConnectionModal: React.FunctionComponent<ModalProps> = (props) => {
  const { modalOpen, onCloseModal } = props;
  const { walletConnectError, resetError } = useWallet();
  const { openAlbyModal, openEmailModal } = useLayout();
  const [legacyFieldOpen, setLegacyFieldOpen] = useState(false);

  return (
    <Dialog
      dialogOpen={modalOpen}
      onClose={() => {
        onCloseModal();
        resetError();
        setLegacyFieldOpen(false);
      }}
      className={"sm:w-[482px] box-border pt-6 pb-9 px-6"}
    >
      <WalletConnectionModalHeader />
      <div className="sm:grid sm:grid-cols-2 sm:gap-6 mx-auto mt-4">
        {activeWalletConfigurations.map(({ displayName, Icon }) => (
          <WalletConnectionButton
            key={displayName}
            walletName={displayName}
            Icon={Icon}
            onClick={() => {
              if (displayName === "Connect with Lightning") {
                onCloseModal();
                openAlbyModal();
              } else if (displayName === "Connect with email") {
                onCloseModal();
                openEmailModal();
              }
            }}
            className={"mt-5 sm:mt-0"}
          />
        ))}
      </div>
      <div className="mt-3 text-center text-small text-secondary-black-500">
        {activeWalletConfigurations.filter(
          (wallet) => wallet.displayName === "Connect with Lightning",
        ).length > 0 && (
          <>
            With Lightning, you can connect using any wallet that supports
            lnurl-auth. These are some popular options: Alby, Blixt, Breez, Blue
            Wallet, Clams, Coinos, LNBits, Phoenix, Sparrow Wallet, ThunderHub,
            Zap Desktop, Zeus
          </>
        )}
      </div>
    </Dialog>
  );
};

export default WalletConnectionModal;
