import classNames from "classnames";
import React, { useState } from "react";
import Button from "../Button/Button";
import TextInput from "./TextInput";
import Spinner from "../UIElements/Spinner";
import { useWallet } from "../WalletProvider";
import Link from "next/link";
import { createZendeskTicket, setUserEmail } from "../../services/reactor/api";
import Checkbox from "./Checkbox";
import Dialog from "../Dialog/Dialog";
import { ModalProps } from "../../services/reactor/types";

const ZendeskRequestForm: React.FunctionComponent<ModalProps> = (props) => {
  const { modalOpen, onCloseModal } = props;
  const { accountAddress, email, jwtToken, refreshAccount } = useWallet();

  const name = accountAddress ? accountAddress : "Not Logged In";

  const [emailInput, setEmail] = useState(email);
  const [subject, setSubject] = useState("Reactor Support Question");
  const [body, setBody] = useState("");

  const [load, setLoad] = useState<boolean>(false);
  const [hasSent, setHasSent] = useState<boolean>(false);

  const [responseError, setResponseError] = useState(false);

  const [saveEmail, setSaveEmail] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoad(true);

    if (saveEmail && email !== emailInput) {
      await setUserEmail({ email: emailInput, jwtToken });
      await refreshAccount();
    }

    createZendeskTicket({
      name,
      email: emailInput,
      subject: subject,
      body: `Account Address: ${accountAddress}\nComment:${body}`,
    })
      .then((response) => {
        setHasSent(true);
        setResponseError(false);
        setLoad(false);
        setBody("");
      })
      .catch((error) => {
        console.error(error.message);
        setResponseError(true);
        setHasSent(true);
        setLoad(false);
      });
  };

  return (
    <Dialog
      dialogOpen={modalOpen}
      onClose={() => {
        if (!load) {
          onCloseModal();
        }
      }}
      className={classNames(
        "sm:w-[450px] text-center",
        "px-8 py-6 bg-white rounded-lg shadow-lg text-secondary-black-500 border border-grayscale-200 rounded-2xl",
      )}
    >
      <div className={classNames("flex justify-center w-full")}>
        {hasSent ? (
          <form>
            {responseError ? (
              <p className="text-center text-secondary-black-500 font-medium mb-2">
                Request <div className="inline text-error-medium">failed</div>.
                Please try again.<br></br>
                <br></br>You may also reach out to{" "}
                <a
                  href="mailto:support@navier.com?subject=Contact%20support"
                  className=""
                >
                  support@navier.com
                </a>{" "}
                or contact us at{" "}
                <Link
                  href="tel:+16502003826"
                  className="inline-block text-primary-blue-500"
                >
                  +1 (650) 200-3826
                </Link>
                .
              </p>
            ) : (
              <p className="text-center text-secondary-black-500 font-medium mb-2">
                Zendesk ticket has been successfully sent. We will respond to
                your request via email as soon as possible.
              </p>
            )}
            <div className="flex justify-center mt-6">
              <Button className="w-[200px]" onClick={onCloseModal}>
                Close
              </Button>
            </div>
          </form>
        ) : (
          <form className="w-full" onSubmit={handleSubmit}>
            <div className="flex flex-col items-center py-2 border-b text-center">
              <h2 className="text-lg font-medium">Submit Support Request</h2>
              or contact us at{" "}
              <Link
                href="tel:+16502003826"
                className="inline-block text-primary-blue-500"
              >
                +1 (650) 200-3826
              </Link>
            </div>
            <div className="mb-6"></div>
            <TextInput
              disabled={load}
              className="mb-6 mt"
              name={"email"}
              type={"email"}
              value={emailInput}
              onChange={(event) => setEmail(event.target.value)}
              title={"Email"}
              required={true}
              placeholder={"example@email.com"}
            />
            {emailInput !== email && (
              <div className="mb-6">
                <Checkbox
                  name="email"
                  label={"update my account email"}
                  disabled={load}
                  checked={saveEmail}
                  onChange={(e) => {
                    setSaveEmail(e.target.checked);
                  }}
                />
              </div>
            )}
            <TextInput
              disabled={load}
              className="mb-6"
              name={"subject"}
              type={"text"}
              value={subject}
              onChange={(event) => setSubject(event.target.value)}
              required={true}
              title={"Subject"}
            />
            <div className="mb-6">
              <p className={"text-secondary-black-500 font-medium mb-2"}>
                Message
              </p>
              <textarea
                required={true}
                disabled={load}
                id="body"
                value={body}
                onChange={(event) => setBody(event.target.value)}
                className={classNames(
                  "w-full block",
                  "p-4 rounded-xl border border-grayscale-300 text-large font-medium placeholder-grayscale-300 placeholder:font-normal",
                  "focus:border-primary-blue-500 focus:outline-none",
                )}
                placeholder={"Describe your issue"}
              />
            </div>
            <div className="flex justify-center items-center gap-4">
              <div className="hidden">
                {/* Hidden submit button that will capture the "Enter" key */}
                <button type="submit" style={{ display: "none" }} />
              </div>
              <Button
                disabled={load}
                variant="secondary"
                onClick={(e) => {
                  e.preventDefault();
                  onCloseModal();
                }}
              >
                Close
              </Button>
              <Button
                disabled={load}
                type="submit"
                className={classNames("w-full")}
              >
                {load ? <Spinner /> : "Submit"}
              </Button>
            </div>
          </form>
        )}
      </div>
    </Dialog>
  );
};

export default ZendeskRequestForm;
