import { abbreviateAccountAddress } from "../util/abbreviateAccountAddress";
import {
  WalletConfiguration,
  walletConfigurations,
  WalletName,
} from "../WalletConnection/walletConfigurations";
import { useCallback } from "react";
import AccountIcon from "../../public/assets/svg/accountIcon.svg";

type Props = {
  isMobile?: boolean;
  walletName: WalletName;
  accountAddress: string;
};

const ConnectedWalletInfo: React.FunctionComponent<Props> = ({
  isMobile = false,
  walletName,
  accountAddress,
}) => {
  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(accountAddress);
  }, [accountAddress]);
  const walletConfiguration = walletConfigurations.find(
    (w) => w.displayName === walletName,
  ) as WalletConfiguration;
  const { Icon } = walletConfiguration;
  return (
    <div
      className="rounded-full px-4 h-12 inline-flex items-center bg-grayscale-50 cursor-pointer hover:bg-grayscale-100"
      onClick={handleClick}
    >
      <div className="w-6 h-6 flex items-center justify-center">
        {/* <Icon className="w-8 h-8" /> */}
        <AccountIcon className="w-8 h-8 transition duration-300 hover:-translate-y-1" />
      </div>
      <p className="ml-3 font-medium text-medium">
        {abbreviateAccountAddress(accountAddress)}
      </p>
    </div>
  );
};

export default ConnectedWalletInfo;
