import React from "react";
import { useRouter } from "next/router";
import { useIsMobile, useBreakpoints } from "../util/mobileBreakpointHooks";
import { useLayout } from "../BaseLayout/BaseLayout";
import SidebarShelf from "./SidebarShelf";
import Button from "../Button/Button";
import SidebarLink from "./SidebarLink";
import Chart from "../../public/assets/svg/chart.svg";
import Home from "../../public/assets/svg/home.svg";
import Wrench from "../../public/assets/svg/wrench.svg";
import NavierLogo from "../../public/assets/svg/reactor-xyz-logo-rbg.svg";
import { useWallet } from "../WalletProvider";

const Sidebar: React.FunctionComponent = () => {
  const isMobile = useIsMobile();
  const { isTabletOrSmaller } = useBreakpoints();
  const router = useRouter();
  const pageUrl = router.asPath;
  const { sidebarShowing, resetLayout } = useLayout();

  const { hasTokens, poolAccount, is_admin } = useWallet();

  const sidebarGuts = (
    <>
      {isTabletOrSmaller && (
        <NavierLogo
          className={
            "mb-10 visited: cursor-pointer object-contain w-[171.5px] transform duration-300 hover:scale-90"
          }
          onClick={() => {
            if (!poolAccount) router.push("/config");
            if (hasTokens) router.push("/dashboard");
            else router.push("/");
          }}
        />
      )}
      <SidebarLink
        active={pageUrl === "/dashboard"}
        disabled={pageUrl === "/dashboard"}
        isMobile={isMobile}
        Icon={Home}
        href={"/dashboard"}
      >
        Dashboard
      </SidebarLink>
      <SidebarLink
        href={"/config"}
        active={pageUrl === "/config"}
        disabled={pageUrl === "/config"}
        className={"mt-10"}
        isMobile={isMobile}
        Icon={Chart}
      >
        Configuration
      </SidebarLink>
      {is_admin && (
        <SidebarLink
          href={"/admin"}
          active={pageUrl === "/admin"}
          disabled={pageUrl === "/admin"}
          className={"mt-10"}
          isMobile={isMobile}
          Icon={Wrench}
        >
          Admin Panel
        </SidebarLink>
      )}
      <div className={"h-[2px] bg-grayscale-300 mt-10"}></div>
      <Button
        variant={pageUrl === "/" ? "primary" : "secondary"}
        disabled={pageUrl === "/"}
        fullWidth
        className={"mt-10"}
        onClick={() => router.push("/")}
      >
        Buy Hashpower
      </Button>
      <Button
        variant={"secondary"}
        fullWidth
        className={"mt-10"}
        onClick={() => {
          router.push("https://about.reactor.xyz");
        }}
      >
        About
      </Button>
    </>
  );

  return (
    <div>
      {isTabletOrSmaller ? (
        <SidebarShelf
          open={sidebarShowing}
          onClose={() => {
            resetLayout();
          }}
          className={"bg-grayscale-50 w-52 h-full box-border p-6 z-20"}
        >
          {sidebarGuts}
        </SidebarShelf>
      ) : (
        <div className={"w-[250px] min-w-[250px] p-6 pt-0 mt-16"}>
          {sidebarGuts}
        </div>
      )}
    </div>
  );
};

export default Sidebar;
