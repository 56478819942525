import classNames from "classnames";
import React, { ReactElement, useEffect, useState } from "react";
import Button from "../Button/Button";
import TextInput from "./TextInput";
import {
  useFeedbackMessagesFunctions,
  FeedbackMessagesManager,
} from "./FeedbackMessagesManager";
import { useWallet } from "../WalletProvider";
import Spinner from "../UIElements/Spinner";
import Checkbox from "./Checkbox";
import { setUserEmail } from "../../services/reactor/api";
interface WaitlistProps {
  title: string;
  text: string;
  successMessage: string;
  onEmailSent?: () => void;
  message: boolean;
  closeButton?: ReactElement;
}

interface WaitlistContextType {
  waitlistSent: boolean;
  setWaitlistSent: React.Dispatch<React.SetStateAction<boolean>>;
}

const WaitlistContext = React.createContext<WaitlistContextType | undefined>(
  undefined,
);

const WaitlistSignup: React.FunctionComponent<WaitlistProps> = ({
  title,
  text,
  successMessage,
  onEmailSent,
  message,
  closeButton,
}) => {
  const { waitlistSent, setWaitlistSent } = useWaitlist();
  const { jwtToken, refreshAccount } = useWallet();
  const { messages, addMessage } = useFeedbackMessagesFunctions();

  const [load, setLoad] = useState(false);
  const [email, setEmail] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (waitlistSent && onEmailSent) onEmailSent();
  }, [waitlistSent, onEmailSent]);

  var sBrowser: string,
    sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf("Firefox") > -1) {
    sBrowser = "Mozilla Firefox";
  }

  const handleEmailSubmit = async () => {
    setLoad(true);
    setUserEmail({ email, jwtToken })
      .then((a) => {
        setWaitlistSent(true);
        setLoad(false);
        refreshAccount();
      })
      .catch((e) => {
        addMessage({
          msg: e.message
            ? e.message
            : "Waitlist signup failed, please try again in a moment",
          status: "failed",
        });
        setLoad(false);
      });
  };

  const [body, setBody] = useState("");
  function validateEmail(email: string) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  return (
    <>
      <div className="flex flex-col items-center py-2 border-b text-center">
        <h2 className="text-lg font-medium mb-3">{title}</h2>
        <p>{text}</p>
      </div>
      {waitlistSent ? (
        <>
          {" "}
          <div className="mt-4 text-center">{successMessage}</div>
        </>
      ) : (
        <>
          <div className="mb-6"></div>
          <TextInput
            disabled={load}
            className="mb-6 mt"
            name={"email"}
            type={"email"}
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            title={"Email"}
            required={true}
            placeholder={"example@email.com"}
          ></TextInput>
          {message && (
            <div className="mb-6 mt-6">
              <p className={"text-secondary-black-500 font-medium mb-2"}>
                Message (optional)
              </p>
              <textarea
                disabled={load}
                id="body"
                value={body}
                onChange={(event) => setBody(event.target.value)}
                className={classNames(
                  "w-full block",
                  "p-4 rounded-xl border border-grayscale-300 text-large font-medium placeholder-grayscale-300 placeholder:font-normal",
                  "focus:border-primary-blue-500 focus:outline-none",
                )}
                placeholder={"Anything else we should know"}
              />
            </div>
          )}
          <div className="my-6">
            <Checkbox
              disabled={load}
              name={""}
              label={<>I agree to receive marketing emails about Reactor</>}
              checked={isChecked}
              onChange={(e) => {
                setIsChecked(e.target.checked);
              }}
            />
          </div>
          <div className="flex justify-center items-center gap-4">
            <Button
              disabled={load || !isChecked}
              type="submit"
              className={classNames("w-full")}
              onClick={async () => {
                if (validateEmail(email)) {
                  await handleEmailSubmit();
                } else {
                  console.error("Invalid email");
                  addMessage({
                    msg: "Invalid email. Please check your email address and try again.",
                    status: "failed",
                  });
                }
              }}
            >
              {load ? <Spinner /> : "Submit"}
            </Button>
            {closeButton}
          </div>
        </>
      )}
      <div className="mt-3">{messages && <FeedbackMessagesManager />}</div>
    </>
  );
};

export default WaitlistSignup;

export const WaitlistProvider: React.FC = ({ children }) => {
  const { email, walletName } = useWallet();

  const [waitlistSent, setWaitlistSent] = useState(false);

  useEffect(() => {
    // users with tokens should not need to sign up again
    if (!!email) setWaitlistSent(true);
  }, [email, walletName]);

  return (
    <WaitlistContext.Provider value={{ waitlistSent, setWaitlistSent }}>
      {children}
    </WaitlistContext.Provider>
  );
};

export function useWaitlist() {
  const context = React.useContext(WaitlistContext);
  if (context === undefined) {
    throw new Error("useWaitlist must be used within a WaitlistProvider");
  }
  return context;
}
