import { createContext, useContext, useState, useCallback } from "react";
import FeedbackMessages, { FeedbackMessagesProps } from "./FeedbackMessages";

const clearTime = Number(process.env.NEXT_PUBLIC_MESSAGE_CLEAR_TIME)
  ? Number(process.env.NEXT_PUBLIC_MESSAGE_CLEAR_TIME)
  : 5000;

type Message = {
  msg: string;
  status?: FeedbackMessagesProps["status"];
};

type FeedbackMessagesManagerProps = {
  autoClearDelay?: number;
};

type FeedbackMessagesHook = {
  messages: Message[];
  addMessage: (message: Message & { autoClear?: number }) => void;
  removeMessage: (msg: string) => void;
  replaceMessagesWithMessage: (
    message: Message & { autoClear?: number },
  ) => void;
  removeMessages: () => void;
};

const FeedbackMessagesContext = createContext<FeedbackMessagesHook | undefined>(
  undefined,
);

const useFeedbackMessages = (
  autoClearDelay = clearTime,
): FeedbackMessagesHook => {
  const [messages, setMessages] = useState<
    Array<{ msg: string; status?: FeedbackMessagesProps["status"] }>
  >([]);

  const removeMessage = useCallback((msg: string): void => {
    setMessages((messages) => messages.filter((m) => m.msg !== msg));
  }, []);

  const addMessage = useCallback(
    (message: Message & { autoClear?: number }): void => {
      const mes = message.msg;
      const possibleIndexOfThisMessage = messages.findIndex(
        (m) => m.msg === mes,
      );
      const weDoNotAlreadyHaveThisMessage = possibleIndexOfThisMessage === -1;
      if (weDoNotAlreadyHaveThisMessage) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { msg: mes, status: message.status || "success" },
        ]);
        if (message.autoClear != 0) {
          if (message.autoClear) {
            setTimeout(() => {
              removeMessage(message.msg);
            }, message.autoClear);
          } else {
            setTimeout(() => {
              removeMessage(message.msg);
            }, autoClearDelay);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [removeMessage, setMessages],
  );

  const replaceMessagesWithMessage = useCallback(
    (message: Message & { autoClear?: number }): void => {
      setMessages([{ msg: message.msg, status: message.status || "success" }]);
      if (message.autoClear != 0) {
        if (message.autoClear) {
          setTimeout(() => {
            removeMessage(message.msg);
          }, message.autoClear);
        } else {
          setTimeout(() => {
            removeMessage(message.msg);
          }, autoClearDelay);
        }
      }
    },
    [removeMessage, autoClearDelay],
  );

  const removeMessages = () => {
    setMessages([]);
  };

  return {
    messages,
    addMessage,
    removeMessage,
    removeMessages,
    replaceMessagesWithMessage,
  };
};

const FeedbackMessagesProvider: React.FC<FeedbackMessagesManagerProps> = ({
  children,
  ...props
}) => {
  const feedbackMessages = useFeedbackMessages(props.autoClearDelay);
  return (
    <FeedbackMessagesContext.Provider value={feedbackMessages}>
      {children}
    </FeedbackMessagesContext.Provider>
  );
};

const useFeedbackMessagesFunctions = (): FeedbackMessagesHook => {
  const feedbackMessages = useContext(FeedbackMessagesContext);
  if (!feedbackMessages) {
    throw new Error(
      "useFeedbackMessagesFunctions must be used within a FeedbackMessagesProvider",
    );
  }
  return feedbackMessages;
};

const FeedbackMessagesManager: React.FC<
  FeedbackMessagesManagerProps
> = ({}) => {
  const { messages } = useFeedbackMessagesFunctions();

  const errorMessages = messages
    .filter((m) => m.status === "failed")
    .map((m) => m.msg);
  const successMessages = messages
    .filter((m) => m.status === "success")
    .map((m) => m.msg);
  const pendingMessages = messages
    .filter((m) => m.status === "pending")
    .map((m) => m.msg);

  return (
    <div className="max-w-[400px]">
      <FeedbackMessages status={"failed"} messages={errorMessages} />
      <FeedbackMessages status={"success"} messages={successMessages} />
      <FeedbackMessages status={"pending"} messages={pendingMessages} />
    </div>
  );
};

export {
  FeedbackMessagesProvider,
  FeedbackMessagesManager,
  useFeedbackMessagesFunctions,
};
