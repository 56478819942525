import React from "react";
import classNames from "classnames";

import { HTMLButtonProps } from "../../services/reactor/types";

type PropsType = {
  size?: "small" | "medium" | "large";
  variant?: "primary" | "secondary" | "link";
  disabled?: boolean;
  fullWidth?: boolean;
} & HTMLButtonProps;

const Button: React.FunctionComponent<PropsType> = (props) => {
  const {
    size = "medium",
    variant = "primary",
    disabled = false,
    fullWidth = false,
    className,
    children,
    ...remainingProps
  } = props;

  return (
    <button
      className={classNames(
        "box-border font-bold",
        size === "small" && `rounded-lg h-8 px-4 text-small`,
        size === "medium" && `rounded-xl h-12 px-4 text-medium`,
        size === "large" && `rounded-xl h-14 px-4 text-large`,
        variant === "primary" &&
          "bg-primary-blue-500 text-white hover:bg-primary-blue-600 hover:active:bg-primary-blue-500",
        variant === "primary" &&
          disabled &&
          "bg-primary-blue-200 hover:bg-primary-blue-200",
        variant === "secondary" &&
          "border border-primary-blue-500 bg-white text-primary-blue-500 hover:text-primary-blue-800 hover:border-primary-blue-800 hover:active:border-primary-blue-500 hover:active:text-primary-blue-500",
        variant === "secondary" &&
          disabled &&
          "text-primary-blue-200 border-primary-blue-200 hover:border-primary-blue-200 hover:text-primary-blue-200",
        variant === "link" &&
          "bg-white text-primary-blue-500 hover:text-primary-blue-800 hover:active:text-primary-blue-500",
        variant === "link" &&
          disabled &&
          "text-primary-blue-200 hover:text-primary-blue-200",
        fullWidth && "w-full",
        className,
      )}
      {...remainingProps}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
