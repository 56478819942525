import React, { useState, useEffect } from "react";
import classNames from "classnames";
import Link from "next/link";
import Image from "next/image";
import { useBreakpoints } from "../util/mobileBreakpointHooks";
import RequestPopup from "../Header/RequestPopup";
import NavierLogo from "../../public/assets/svg/reactor-xyz-logo-cmyk-white.svg";
import { useWallet } from "../WalletProvider";

type FooterProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLElement>,
  HTMLElement
> & {
  showZendeskWindow?: () => void;
  blurred?: boolean;
};

const Footer: React.FunctionComponent<FooterProps> = ({
  showZendeskWindow,
  className,
  blurred,
  ...props
}) => {
  // TODO: consider internationalizing if necessary
  const currentYear = new Date().getFullYear();
  const { isTabletOrSmaller } = useBreakpoints();
  const { accountAddress } = useWallet();
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    setInitializing(false);
  }, [isTabletOrSmaller]);

  if (initializing) return null;

  return (
    <footer
      className={classNames(
        "bg-primary-blue-500 text-white text-center px-10 py-8",
        blurred && "blur-sm",
      )}
    >
      <div className="max-w-[1500px] mx-auto">
        <div className="flex items-center justify-between">
          <div className="max-h-[60px] min-w-[60px]">
            {isTabletOrSmaller ? (
              <div className="mr-5">
                {showZendeskWindow && (
                  <RequestPopup showZendeskWindow={showZendeskWindow} />
                )}
              </div>
            ) : (
              <Link href="/" passHref aria-current="page">
                <NavierLogo
                  className="hover:cursor-pointer transform duration-300 hover:scale-90"
                  width="200"
                  height="60"
                />
              </Link>
            )}
          </div>
          <div>
            <ul role="list" className="w-list-unstyled">
              <li className="list-item-7"></li>
            </ul>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="text-black">
              <a
                href={`mailto:support@navier.com?subject=Contact%20support%for%account%${accountAddress}`}
                className="flex overflow-hidden w-9 h-9 justify-center items-center rounded-full bg-navier-blue transition duration-300 hover:-translate-y-1"
              >
                <Image
                  src="https://assets.website-files.com/62c48251b41db668122e8d70/639a222dd968c1f80a769c23_Property%201%3Dbold.png"
                  loading="lazy"
                  alt=""
                  className="max-w-20[px]"
                  width="20"
                  height="20"
                />
              </a>
            </div>
            <a
              href="https://twitter.com/reactor_xyz"
              target="_blank"
              rel="noreferrer"
              className="flex overflow-hidden w-9 h-9 justify-center items-center rounded-full bg-navier-blue transition duration-300 hover:-translate-y-1"
            >
              <Image
                src="https://assets.website-files.com/616849528c1f3d68b05e9ad3/61de7d4e60123a4c8726d3ba_logo-twitter.svg"
                loading="lazy"
                alt=""
                className="max-w-20[px]"
                width="20"
                height="20"
              />
            </a>
            <a
              href="https://discord.com/invite/9GufzRvfku"
              target="_blank"
              rel="noreferrer"
              className="flex overflow-hidden w-9 h-9 justify-center items-center rounded-full bg-navier-blue transition duration-300 hover:-translate-y-1"
            >
              <Image
                src="https://assets.website-files.com/616849528c1f3d68b05e9ad3/61de7d4eea829152f3be3d47_discord.svg"
                loading="lazy"
                alt=""
                className="max-w-20[px]"
                width="20"
                height="20"
              />
            </a>
          </div>
        </div>
        <div
          className={classNames(
            "flex items-center justify-between pt-10 text-sm",
            isTabletOrSmaller && "flex flex-col",
          )}
        >
          <div className={classNames(isTabletOrSmaller && "mb-5")}>
            <div>&copy; {currentYear} Navier Inc. All rights reserved.</div>
          </div>
          <div
            className={classNames(
              "flex justify-between flex-row mb-2 text-sm light",
              isTabletOrSmaller ? "w-full text-xs" : "text-sm space-x-5",
            )}
          >
            <div
              className="cursor-pointer hover:text-secondary-black-200"
              onClick={() => {
                window.open(
                  "https://about.reactor.xyz/privacy-policy",
                  "_blank",
                );
              }}
            >
              Privacy Policy
            </div>
            {isTabletOrSmaller && (
              <div className="text-primary-blue-800">|</div>
            )}
            <div
              className="cursor-pointer hover:text-secondary-black-200"
              onClick={() => {
                window.open("https://about.reactor.xyz/terms-of-use", "_blank");
              }}
            >
              Terms of Use
            </div>
            {isTabletOrSmaller && (
              <div className="text-primary-blue-800">|</div>
            )}
            <div
              className="cursor-pointer hover:text-secondary-black-200"
              onClick={() => {
                window.open("tel:+16502003826");
              }}
            >
              +1 (650) 200-3826
            </div>
            {isTabletOrSmaller && (
              <div className="text-primary-blue-800">|</div>
            )}
            {isTabletOrSmaller && (
              <div
                className="cursor-pointer hover:text-secondary-black-200"
                onClick={() => {
                  window.open("https://about.reactor.xyz", "_blank");
                }}
              >
                About Us
              </div>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
